
.c-bar {width: var(--c-barWidth); z-index: 5; position: relative; background-color: var(--barBkg);} /* To avoid overflowing from the side bars, z-index is set to a higher values than the side bars and the background and position relative are set*/
.t-bar-logo {height: 27px;width: auto; margin-right: 24px; overflow: hidden; }
.logo-cont {height: auto;}
.tbar-center {display: flex; justify-content: space-between;align-items: center;flex-wrap: nowrap; height: 100%}
.tbar-center-mobile {display: none}

@media only screen and (max-width: 1068px) {
    .t-bar-logo { height: 25px;margin-right: 18px; overflow: hidden; }
}

@media only screen and (max-width: 734px) {
    .tbar-center-mobile {justify-content: center;align-items: center;flex-wrap: nowrap; display: flex; height: 100%; }
    .tbar-center {display: none}
    .t-bar-logo {height: 7.2vw;width: auto; margin-right: 0; overflow: hidden; }


}
