.breadcrumb-dark {--bd-linkColor: white;--bd-arrowColor: rgb(235,235,235);--bd-textColor: rgb(245,245,245);}
.breadcrumb-bright {--bd-linkColor: #0071c2;--bd-arrowColor: rgb(107, 107, 107));--bd-textColor: rgb(107, 107, 107);}
.breadcrumb-abs-pos {position: absolute; top: 12px; left: 0; width: 100%;}
.breadcrumb-abs-cont{max-width: var(--cn-art-maxWidth); margin: 0 auto;padding: 0 var(--cn-art-latPadding); width: 100%;}
.rba-breadcrumb {padding: 0;  list-style-type: none;margin: 0;font-size: 14px;  font-weight: 400;  line-height: 18px;  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;}
.rba-breadcrumb li {margin: 8px 0 0 8px;display: inline-block;}
.breadcrumb__icon  { height: 16px;  width: 16px; vertical-align: top; margin: 8px 0 0 8px; display: inline-block;fill: var(--bd-arrowColor)}
.breadcrumb-link { color: var(--bd-linkColor);}
.breadcrumb-link:hover {text-decoration: underline;}
.breadcrumb-link:last-of-type { color: var(--bd-textColor) }
.breadcrumb-link:last-of-type:hover { text-decoration: none;color: var(--bd-textColor) }
.breadcrumb__icon:last-child {display: none}
