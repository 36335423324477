.den-row-defs {--de-row-padding: 16px; --de-row-marBot: 16px; --imgWidth: 200px;--row-body-leftMrg:8px; --titleSize: 18px;  --titleMargBot: 8px;
  --subtitlesMarTop: 0;--streetSize:13px;--phoneIcon:18px;--contactsTopMrg:4px;}
.den-doc-row { border-radius: 2px; background-color: white); border: 1px solid #c6c6c6; margin-bottom: var(--de-row-marBot);
  width: calc(50% - 0.5 * var(--de-row-padding));height: calc(2 * var(--de-row-padding) + var(--imgWidth))}
.den-row-inner {padding: var(--de-row-padding);display: flex; font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif; color: rgb(38, 38, 38);}
.den-row-pic  { -ms-flex: 0 0 auto;  flex: 0 0 auto;  width: var(--imgWidth);}
.den-row-pic img  {  border-radius: 4px;  display: block;  width: var(--imgWidth); height: var(--imgWidth);}
.den-row-body  { flex: 1 1 auto;  margin-left: var(--row-body-leftMrg);  width: calc(100% - var(--imgWidth) - var(--row-body-leftMrg));}
.den-row-b1 {margin-left: var(--de-row-padding);height: 100%}
.den-row-b1-inner {    display: flex;  flex-basis: 100%; flex-direction: column;flex-wrap: nowrap;justify-content: space-between;height: 100%;}
.den-row-title {margin: 0 0 var(--titleMargBot) 0; padding: 0;  font-size: var(--titleSize);  font-weight: 700;  line-height: 1.25;color: rgb(0, 113, 194);min-height: calc(2 * 1.25 * var(--titleSize))}
.den-row-street {margin: var(--subtitlesMarTop) 0; font-size: var(--streetSize);line-height: 1.3;font-weight: 400}
.den-row-contacts {display: flex;flex-wrap: wrap;width: 100%;justify-content: space-between;margin-top: var(--contactsTopMrg)}
.den-row-map-cont {position: absolute;right: 20px; top:20px;z-index: 10;}
.den-row-map-fullscreesmBtn {position: absolute;left: 15px; top:36px;z-index: 10;display: flex;justify-content: flex-start;}
.den-row-action {margin-right:6px;}
.den-row-action:last-child {margin-right: 0;}
.den-cont-row-phone, .den-cont-row-whatsapp { width: 50%;display: flex;justify-content: flex-start;align-items: center;height: 23px;font-size: 14px;line-height: 18px;font-weight: 400}
.den-cont-row-icon-phone, .den-cont-row-icon-whatsapp {width:  var(--phoneIcon); height: var(--phoneIcon); line-height: 1;margin-right: 8px}
.den-cont-row-icon-phone .material-icons {font-size: var(--phoneIcon) !important; color: dodgerblue !important}
.den-cont-row-icon-whatsapp .material-icons {font-size: var(--phoneIcon) !important; color: green !important}
.den-cont-row-visita { width: 100%;display: flex;justify-content: flex-start;align-items: center;height: 23px;font-size: 14px;line-height: 18px;font-weight: 400}
.den-cont-row-icon-visita {width:  var(--phoneIcon); height: var(--phoneIcon); line-height: 1;margin-right: 8px; }
.den-cont-row-icon-visita .material-icons {font-size: var(--phoneIcon) !important; color: red !important}
.den-cont-row-but {width: 50%;display: flex;justify-content: flex-start;align-items: center;height: 22px;}
.den-cont-row-icon-but {width:  22px; height: 22px; line-height: 1;margin-right: 12px}
.den-cont-row-icon-but .material-icons {font-size: 22px !important; color: green !important}
@media only screen and (max-width: 1068px) {
  .den-row-defs {--de-row-padding: 10px; --de-row-marBot: 16px; --imgWidth: 200px;--row-body-leftMrg:6px; --titleSize: 17px;  --titleMargBot: 7px;
    --subtitlesMarTop: 0;--streetSize:13px;--phoneIcon:18px;--contactsTopMrg:4px;}
}

@media only screen and (max-width: 734px)  {
    .den-row-defs {--de-row-padding: 8px; --de-row-marBot: 12px; --imgWidth: 33vw;--row-body-leftMrg: 8px;--titleSize: 3.3vw;--titleMargBot:4px;--subtitlesMarTop: 0;
      --streetSize:12px;--phoneIcon:13px;--contactsTopMrg:4px;}
    .den-doc-row  {width: calc(100% - 16px); margin-left: 8px;margin-right: 8px;}
    .den-cont-row-visita {display: none;}
    .den-cont-row-phone{width: 100%;font-size: 13px;line-height: 15px;height: 18px;}
    .den-cont-row-whatsapp {display: none}
}
@media only screen and (max-width: 350px)  {
  .den-row-defs {--contactsTopMrg:2px;--phoneIcon:14px;--titleMargBot:2px;}
  .den-cont-row-phone{width: 100%;font-size: 12px;line-height: 15px;height: 15px;}
}
