.material-iconss:hover {
    color: blue !important;
    cursor: pointer;
}

.light-blue-arrow .material-icons:hover {
    color: #87CEEB!important;
    cursor: pointer;
}
.noHover .material-icons:hover {
    color: white!important;
    cursor: pointer;
}
.back-img-cover {background-repeat: no-repeat; background-size: cover;}
.justify { text-align: justify;text-justify: inter-word;}
.full-height {min-height: 100vh;}
.linHe1 {line-height: 1}
.footer {line-height: 1.2; position: relative; z-index: 2}
.overlay{position:fixed;width:100%;height:100%;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0.7);z-index:9}
a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

.h20 {height: 20px} .h22 {height: 2px} .h24 {height: 24px} .h26 {height: 26px} .h28 {height: 28px} .h30 {height: 30px} .h32 {height: 32px} .h34 {height: 34px} .h36 {height: 36px} .h38 {height: 38px} .h40 {height: 40px} .h42 {height: 42px} .h44 {height: 44px} .h46 {height: 46px} .h48 {height: 48px} .h50 {height: 50px} .h60 {height: 60px} .h70 {height: 70px} .h80 {height: 80px} .h90 {height: 90px} .h100 {height: 100px}
 .h-100 {height: 100%} .mh100 {max-height: 100%}
.w5 {width: 5%; overflow: hidden} .w10 {width: 10%; overflow: hidden} .w15 {width: 15%; overflow: hidden} .w20 {width: 20%; overflow: hidden} .w25 {width: 25%; overflow: hidden} .w30 {width: 30%; overflow: hidden} .w35 {width: 35%; overflow: hidden} .w40 {width: 40%; overflow: hidden} .w45 {width: 45%; overflow: hidden} .w50 {width: 50% !important; overflow: hidden}.w60 {width: 60%; overflow: hidden}.w-60{width: 60px; overflow: hidden} .w70 {width: 70%; overflow: hidden} .w80 {width: 80%; overflow: hidden} .w90 {width: 90%; overflow: hidden} .w100 {width: 100%; overflow: hidden}.w-16 {width: 16px}.w-18 {width: 18px}.w-20 {width: 20px}.w-100 {width: 100px; overflow: hidden}.w-200 {width: 200px; overflow: hidden}
.maxW100 {max-width: 100px !important; overflow: hidden} .maxW200 {max-width: 200px !important; overflow: hidden} .maxW300 {max-width: 300px !important; overflow: hidden}  .maxW400 {max-width: 400px !important; overflow: hidden}  .maxW500 {max-width: 500px !important; overflow: hidden} .maxW600 {max-width: 600px !important; overflow: hidden} .maxW700 {max-width: 700px !important; overflow: hidden} .maxW800 {max-width: 800px !important; overflow: hidden}
.valign-center {
    display: flex;
    align-items: center;
}

.round-top {border-radius: 8px 8px 0 0}
.round-bottom {border-radius: 0 0 8px 8px}
.roundLeft {border-radius: 30px 8px 8px 30px}

.flex-cen-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.flex-cen-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.flex-cen-cen {
    display: flex;
    align-items: center;
    justify-content: center;
}

.val-just-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

[class*="slideArrowLeft-"] {

        width: calc( 8 * (1vh + 1vw) / 2 );
        height: calc( 20 * (1vh + 1vw) / 2 );
        opacity: 0.4;
        box-shadow: calc((1vh + 1vw) / 8) calc((1vh + 1vw) / 8) 0 0 gray;
}



.pointer {
    cursor:pointer;
}
.cursorAuto {
    cursor:auto;
}
.dropCenter {
    left: 0;
    top: 100%;

}

.dropRight {
    right: 0;
    top: calc(100% - 4px);
}

.dropLeft {
    left: 0;
    top: 100%;
}


.gridPad {
    padding: 0 8px;
}




.cLeft {
    position: fixed;
    top: 50vh;
    left: 0vw;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 5;

}

.cRight {
    position: fixed;
    top: 50vh;
    right: 0vw;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 5;
}

[class*="slideArrowRight-"] {

        width: calc( 8 * (1vh + 1vw) / 2 );
        height: calc( 20 * (1vh + 1vw) / 2 );
        opacity: 0.5;
        box-shadow: 0 0 calc((1vh + 1vw) / 8) calc((1vh + 1vw) / 8) gray;


}

.container8 {
padding: 0px 4px 0 8px;
}

.noMargin {margin: 0px 0px 0px 0px !important}
.noPadding {padding: 0px 0px 0px 0px !important}

div.slideArrowLeft:hover {
    opacity: 1;

}

div.slideArrowRight:hover {
    opacity: 1;

}



.top100 {
    min-height: 100%;
}

.main {
    position: relative;
}


.Hbutton {
    font-size: 3.5vw;
}

[class*="mBotRes-"] {
   margin-bottom: 0px;
}



.title {
    font-size: 6vw;
}




.title-Side {
    font-size: 3.5vw;
}



[class*="mtop-"] {

        margin-top: 75px;

}

[class*="wid-"] {
    max-width: 100%;
}

[class*="minWid-"] {
    min-width: 80%;
}


.center {
    position: fixed;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 5;
}


.overflow-auto {

    overflow-y: auto;
}
.overflow-hidden {

    overflow: hidden;
}

.cent {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 5;
}


.mtop75
    {
        position: relative;
        margin-top: 75px;
    }
.mtop50
{
    position: relative;
    margin-top: 50px;
}

.mbot75
{
    position: relative;
    margin-bottom: 75px;
}

.alert {
    position: fixed;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 5;
    display: none;
}



@media only screen and (min-width: 450px)  {
    .Hbutton {
        font-size: 3vw;
    }



}



@media only screen and (max-width: 500px)  {
    .hide599 {
        display: none;
    }

}

@media only screen and (min-width: 500px)  {

    .Hbutton {
        font-size: 2.5vw;
    }

}


@media only screen and (max-width: 600px)  {

    .top50-s {
        position: relative;
        margin-top: 20px;
    }

    .mtop-s {
        position: relative;
        margin-top: 40px;
    }
    .mtop-sx {

        margin-top: 0px;
    }
    .minWid70 {
        min-width: 70%;
    }

    .top-s {
        position: relative;
        margin-top: 15vh;
    }
    .top-ss {
        position: relative;
        margin-top: 25vh;
    }
    .top-sp {
        position: relative;
        margin-top: 20vh;
    }
}


@media only screen and (min-width: 601px)  {

    .mBotRes-600 {
    margin-bottom: 16px;
}

    .gridPad {
    padding: 0 12px;
}




    .title {
        font-size: 3vw;
    }

    .title-Side {
        font-size: 2vw;
    }
    .minWid-s {
        min-width: 60%;
    }

    .mtop-m {
        position: relative;
        margin-top: 50px;
    }

    .top-m {
        position: relative;
        margin-top: 15vh;
    }
    .top-ms {
        position: relative;
        margin-top: 30vh;
    }
    .top-mp {
        position: relative;
        margin-top: 25vh;
    }

    .top50-m {
        position: relative;
        margin-top: 50px;
    }



    .wid-s {
        max-width: 60vw;
    }

    .wid-sx {
        max-width: 100%;
    }

    .Hbutton{
        font-size: 2vw;
    }



}


.centerAbs {
    margin-top: 32px;
    }




@media only screen and (min-width: 900px)  {

.centerAbs {
    margin-top: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
}

    .title {
        font-size: 2vw;
    }

    .popUpTitle {
        font-size: 3vw;
    }

    .slideArrowLeft-l {
        width: calc( 4 * (1vh + 1vw) / 2 );
        height: calc( 9 * (1vh + 1vw) / 2 );
        opacity: 0.4;
        box-shadow: calc((1vh + 1vw) / 8) calc((1vh + 1vw) / 8) 0 0 gray;

    }

    .slideArrowRight-l {
        width: calc( 4 * (1vh + 1vw) / 2 );
        height: calc( 9 * (1vh + 1vw) / 2 );
        opacity: 0.4;
        box-shadow: 0 0 calc((1vh + 1vw) / 8) calc((1vh + 1vw) / 8) gray;

    }

    .minWid-m {
        min-width: 40%;
    }

    .wid-m {
        max-width: 40vw;
    }

    .wid-mx {
        max-width: 80vw;
    }

    .Hbutton{
        font-size: 13px;
    }

}

@media only screen and (min-width: 1100px)  {

    .minWid-l {
        min-width: 25%;
    }
    .wid-l {
        max-width: 30vw;
    }
    .wid-lx {
        max-width: 60vw;
    }
    .top50-l {
        position: relative;
        margin-top: 50px;
    }

    .mtop-l {
        position: relative;
        margin-top: 75px;
    }

    .top-l {
        position: relative;
        margin-top: 25vh;
    }
    .top-ls {
        position: relative;
        margin-top: 40vh;
    }
    .top-lp {
        position: relative;
        margin-top: 35vh;
    }


}

@media only screen and (min-width: 1200px)  {

    div.maw30 {
        max-width: 30%;
    }


    .title {
        font-size: 1.5vw;
    }

    .popUpTitle {
        font-size: 36px;
    }

 }

    @media only screen and (min-width: 1400px)  {

        .gridPad {
            padding: 0 16px;
        }

}

    @media only screen and (min-width: 1900px)  {




    }
