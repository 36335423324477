.col-fBlu-l5 {color:#000 ; background-color:#f3f6fb !important}
.col-fBlu-l4 {color:#000 ; background-color:#d8e0f1 !important}
.col-fBlu-l3 {color:#000 ; background-color:#b2c2e2 !important}
.col-fBlu-l2 {color:#fff ; background-color:#8ba3d4 !important}
.col-fBlu-l1 {color:#fff ; background-color:#6485c6 !important}
.col-fBlu-d1 {color:#fff ; background-color:#3b5da1 !important}
.col-fBlu-d2 {color:#fff ; background-color:#35538f !important}
.col-fBlu-d3 {color:#fff ; background-color:#2e497d !important}
.col-fBlu-d4 {color:#fff ; background-color:#283e6b !important}
.col-fBlu-d5 {color:#fff ; background-color:#213459 !important}

.col-fBlu-light {color:#000 ; background-color:#f3f6fb !important}
.col-fBlu-dark {color:#fff ; background-color:#213459 !important}
.col-fBlu-action {color:#fff ; background-color:#213459 !important}

.col-fBlu {color:#fff ; background-color:#4267b2 !important}
.col-fBlu-text {color:#4267b2 !important}
.col-fBlu-d1-text {color:#3b5da1 !important}
.col-fBlu-d2-text {color:#35538f !important}
.col-fBlu-d3-text {color:#2e497d !important}
.col-fBlu-d4-text {color:#283e6b !important}
.col-fBlu-d5-text {color:#213459 !important}
.col-fBlu-border {border-color:#4267b2 !important}


.fbBKG {background-color: rgb(240, 242, 245)}

.col-fBlu-hover:hover {color:#fff !important; background-color:#4267b2 !important}
.col-fBlu-hover-text:hover {color:#4267b2 !important}
.col-fBlu-d1-hover-text:hover {color:#3b5da1 !important}
.col-fBlu-d2-hover-text:hover {color:#35538f !important}

.col-fBlu-hover-border:hover {border-color:#4267b2 !important}



.w3-blue-l5 {color:#000 !important; background-color:#f2f9fe !important}
.w3-blue-l4 {color:#000 !important; background-color:#d2eafd !important}
.w3-blue-l3 {color:#000 !important; background-color:#a6d4fa !important}
.w3-blue-l2 {color:#000 !important; background-color:#79bff8 !important}
.w3-blue-l1 {color:#fff !important; background-color:#4daaf6 !important}
.w3-blue-d1 {color:#fff !important; background-color:#0c87eb !important}
.w3-blue-d2 {color:#fff !important; background-color:#0b78d1 !important}
.w3-blue-d3 {color:#fff !important; background-color:#0a69b7 !important}
.w3-blue-d4 {color:#fff !important; background-color:#085a9d !important}
.w3-blue-d5 {color:#fff !important; background-color:#074b83 !important}

.w3-blue-light {color:#000 !important; background-color:#f2f9fe !important}
.w3-blue-dark {color:#fff !important; background-color:#074b83 !important}
.w3-blue-action {color:#fff !important; background-color:#074b83 !important}

.w3-blue {color:#fff !important; background-color:#2196f3 !important}
.w3-text-blue {color:#2196f3 !important}
.w3-border-blue {border-color:#2196f3 !important}

.w3-hover-blue:hover {color:#fff !important; background-color:#2196f3 !important}
.w3-hover-blue-l1:hover {color:#fff !important; background-color:#4daaf6 !important}
.w3-hover-blue-d2:hover {color:#fff !important; background-color:#0b78d1 !important}
.w3-hover-text-blue:hover {color:#2196f3 !important}
.w3-hover-border-blue:hover {border-color:#2196f3 !important}


.col-yelBook-l5 {color:#000 ; background-color:#fffbf0 !important}
.col-yelBook-l4 {color:#000 ; background-color:#fff1cc !important}
.col-yelBook-l3 {color:#000 ; background-color:#fee49a !important}
.col-yelBook-l2 {color:#000 ; background-color:#fed667 !important}
.col-yelBook-l1 {color:#000 ; background-color:#fec834 !important}
.col-yelBook-d1 {color:#000 ; background-color:#e4a801 !important}
.col-yelBook-d2 {color:#fff ; background-color:#cb9501 !important}
.col-yelBook-d3 {color:#fff ; background-color:#b28201 !important}
.col-yelBook-d4 {color:#fff ; background-color:#987001 !important}
.col-yelBook-d5 {color:#fff ; background-color:#7f5d01 !important}

.col-yelBook-light {color:#000 ; background-color:#fffbf0 !important}
.col-yelBook-dark {color:#fff ; background-color:#7f5d01 !important}
.col-yelBook-action {color:#fff ; background-color:#7f5d01 !important}

.col-yelBook {color:#000 !important; background-color:#febb02 !important}
.col-yelBook-text {color:#febb02 !important}
.col-yelBook-border {border-color:#febb02 !important}

.col-yelBook-hover:hover {color:#000 !important; background-color:#febb02 !important}
.col-yelBook-hover-text:hover {color:#febb02 !important}
.col-yelBook-hover-border:hover {border-color:#febb02 !important}
