.practice-box-container {width: 100%; margin: 32px 0}

.pr-contact-header {padding: 24px;color: rgb(38,38,38)}

.pr-contact-name {font-size: 18px; font-weight: 500;line-height: 1.3; color: rgb(0,0,0)}
.pr-contact-street {font-size: 17px; color: rgb(38,38,38);line-height: 1.3; margin: 8px 0}
h4.pr-contact-street {font-weight: 300; margin: 0;color: rgb(38,38,38)}
.pr-cont-street-text {font-weight: 300; color: rgb(99,99,99); padding-left: 2px}
.cont-map-cont {width: 100%;height: 300px;position: relative;}

.pr-contact-footer {padding: 24px;}
