/*
Colors definitions.
--h --s --l sets the background for filled buttons, the text color and border for bordered buttons. background and border hover use the same hsl value incrising only the luminosity as specified directly in the button, the luminosity on hover is not set in the colors definition
--btn-color sets the text color only for filled buttons.
*/
.a-btn-text-white { --btn-color: rgb(247,247,247)}
.a-btn-blue-1 {--h: 205.1; --s: 100%; --l: 42%; --btn-color: white}
.a-btn-blue-2 {--h: 212; --s: 67%; --l: 46%;--btn-color: white}
.a-btn-FBblue {--h: 220; --s: 46%; --l: 48%;--btn-color: white;}
.a-btn-orange-1 {--h: 35; --s: 95%; --l: 49%;--btn-color: white}
.a-btn-orange-2 {--h: 22; --s: 100%; --l: 50%;--btn-color: white}
.a-btn-green {--h: 140; --s: 27%; --l: 56%;--btn-color: white}
.a-btn-gray {--h: 0; --s: 0%; --l: 50%;--btn-color: white}
.a-btn-whatsapp-green {--h: 142; --s: 70%; --l: 49%;--btn-color: white}
.a-btn-black {--h: 0; --s: 0%; --l: 0%;--btn-color: white}
.a-btn-ukr-flag {--h: 212; --s: 100%; --l: 36%;--btn-color: #FFD700}
/*

gradient orange: linear-gradient(25deg, rgb(243, 128, 32), rgb(248, 173, 76))


*/
