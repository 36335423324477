.cn-defs {
  --cn-art-maxWidth: 1100px;--cn-art-sideWidth:30%;--cn-art-latPadding: 0;
  --cn-title-topMarg: 12px;--cn-title-h3-topMarg: 16px;--cn-title-botMarg: 8px; --cn-title-size: 40px; --cn-meta-size: 16px;--cn-meta-pic-height: 60px;
  --cn-par-size: 18px;--cn-par-verMarg: 18px; --cn-par-title-size: 32px;
}
.cn-art-cont {padding-bottom: 40px;min-height: 100vh;}
.cn-body {max-width: var(--cn-art-maxWidth); margin: 0 auto;padding: 0 var(--cn-art-latPadding);}
.cn-main {width:calc(100% - var(--cn-art-sideWidth)) }
.cn-side {width: var(--cn-art-sideWidth); margin-left: 20px}

.cn-back-btn {position: fixed; top: 32px; left:32px;z-index: 100}

@media only screen and (max-width: 1068px) {
    .cn-defs {
      --cn-art-maxWidth: 100%; --cn-art-latPadding: 30px;--cn-art-sideWidth:27%;
      --cn-title-topMarg: 12px;--cn-title-h3-topMarg: 16px;--cn-title-botMarg: 8px; --cn-title-size: 40px;
    }
}

@media only screen and (max-width: 734px) {
  .cn-back-btn {position: fixed; top: 16px; left:16px;}
    .cn-defs {
      --cn-art-maxWidth: 100%;--cn-art-sideWidth:0;--cn-art-latPadding: 5vw;
      --cn-title-topMarg: 12px;--cn-title-h3-topMarg: 16px;--cn-title-botMarg: 8px; --cn-title-size: max(20px , 6vw);--cn-meta-size: 12px;--cn-meta-pic-height: 45px;
    }
    .cn-side { margin-left: 0}
}
