.top-bar-defs-abs {--tBarHeight: 75px; --barBkg: transparent; --intraMargin:16px; --sidesMergins: 25px;  --c-barWidth: min(1100px, 60vw); }
.top-bar-defs {--tBarHeight: 60px; --barBkg:  hsl(var(--h),var(--s),var(--l)); --intraMargin:16px; --sidesMergins: 16px; --c-barWidth: min(1100px, 60vw); }
.top-bar-defs-bordered {--tBarHeight: 70px; --barBkg:  hsl(var(--h),var(--s),var(--l)); --intraMargin:20px; --sidesMergins: 20px; --c-barWidth: 1380px; }

.top-bar-container {background-color: var(--barBkg); height: var(--tBarHeight); width: 100%; z-index: 7; position: relative;}
.top-bar-fixed {position: fixed; top:0; left: 0}
.top-bar-absolute {position: absolute; top:0; left: 0}

.tbar-flex-cont {display: flex; height: 100%; flex-wrap: nowrap; align-content: center; justify-content: space-between;}
.top-bar-container-overlay {content: "";  position: absolute;  height: 100%;  top: 0;  left: 0;  right: 0;  background-image: linear-gradient(0deg,hsla(0,0%,100%,0),rgba(0,0,0,.5) 90%, rgba(0,0,0,.7) 100%);}
.tbar-mobile {display: none !important;}
.tbar-std {display: flex !important;}

@media only screen and (max-width: 1068px) {
  .top-bar-defs-abs {--tBarHeight: 75px; --barBkg: transparent; --intraMargin:6px; --sidesMergins: 10px;  --c-barWidth: min(1100px, 65vw); }
  .top-bar-defs {--tBarHeight: 60px; --barBkg:  hsl(var(--h),var(--s),var(--l)); --intraMargin:6px; --sidesMergins: 10px; --c-barWidth: min(1100px, 65vw); }
  .top-bar-defs-bordered {--tBarHeight: 70px; --barBkg:  hsl(var(--h),var(--s),var(--l)); --intraMargin:6px; --sidesMergins: 10px;--c-barWidth: min(1100px, 65vw); }
}

@media only screen and (max-width: 734px) {
  .top-bar-defs {--intraMargin:6px; --sidesMergins: 6px;--tBarHeight: 60px;--c-barWidth: 40vw;}
  .top-bar-defs-abs {--c-barWidth: 45vw;}
  .tbar-mobile {display: flex !important}
  .tbar-std {display: none !important}
}
