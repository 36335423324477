.side-men-anim-next {animation: side-men-anim-next 400ms; transform: translateX(-50%); -webkit-transform: translateX(-50%);}
.side-men-anim-prev {animation: side-men-anim-prev 400ms;transform: translateX(0); -webkit-transform: translateX(0);}
.side-menu-fad-opac{opacity: 0.1;}

@-webkit-keyframes side-men-anim-next {
  from {
      -webkit-transform: translateX(0);
      transform: translateX(0);
  }

  to {
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);

  }
}

@keyframes side-men-anim-next {
  from {
      -webkit-transform: translateX(0);
      transform: translateX(0);
  }

  to {
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);

  }
}

@-webkit-keyframes side-men-anim-prev {
  from {
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);

  }

  to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
  }
}

@keyframes side-men-anim-prev {
  from {
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
  }

  to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
  }
}
