.rba-header-img-defs {--h1size: 40px;--h3size: 25px;  --h1-margBot: 30px;--header-height:35vh; --titleWidth: 90%}

.cn-header-image-nometa {width:calc(100% - var(--cn-art-sideWidth)); margin: 24px 0 0 0}
.cn-header-image-meta {width:calc(100% - var(--cn-art-sideWidth)); margin: 16px 0 0 0}

.acFu-title {margin: var(--cn-title-topMarg) 0 var(--cn-title-botMarg) 0;padding: 0;line-height: 1.1; font-size: var(--h1size); width: var(--titleWidth);font-weight: 700; color: rgb(38,38,38); text-align: left;
  font-family: Neue,Helvetica,Arial,Utkal,sans-serif; font-feature-settings: kern; text-rendering: optimizeLegibility; -webkit-font-smoothing: antialiased;}
.acFu-title-h3 {margin: var(--cn-title-h3-topMarg) 0 var(--cn-title-botMarg) 0;padding: 0;line-height: 1.1; font-size: var(--h3size); width: var(--titleWidth);font-weight: 400; color: rgb(78,78,78); text-align: left;
    font-family: Neue,Helvetica,Arial,Utkal,sans-serif; font-feature-settings: kern; text-rendering: optimizeLegibility; -webkit-font-smoothing: antialiased;}

  @media only screen and (max-width: 1068px) {
    .rba-header-img-defs {--h1size: 4vw;--h3size: 2vw;  --h1-margBot: 30px;--header-height:40vh}
  }

  @media only screen and (max-width: 734px) {
    .rba-header-img-defs {--h1size: 6.4vw;--h3size: 4.4vw; --h1-margBot: 3vh; --header-height:43vh; --titleWidth: 100%}


  }
