
.a-btn-drop-defs {--dropWidth: 200px; --dropFontCol:rgb(15, 15, 18);--dropUlBkg: white; --dropUlBkgHov: #f5f5f5;--dropBrdRadius: 6px; --dropBoxShColor: rgba(0,0,0,.7);
  --toolTipSize: 6px; --toolTipRightMarg: 19px; --toolTipLeftMarg: 25%; --dropFontSize: 14px; --dropLineHe: 17px; --dropLiPadding: 14px 14px;--dropFontWeigh: 500}


.a-btn-drop ul.a-btn-drop-menu { position: absolute;bottom: 0; width: var(--dropWidth); transform: translateY(100%);padding: 0;background: var(--dropUlBkg);  border-radius: var(--dropBrdRadius);
   border: none;z-index: 100; box-shadow: 0 2px 8px var(--dropBoxShColor); margin: 0; list-style: none; display: none; }

.a-btn-drop.a-btn-bordOnHover:hover {border-color: transparent}
.a-btn-drop-menu.drop-left {right: 0;}
.a-btn-drop-menu.drop-right {left: 0;}
.a-btn-drop:hover ul.a-btn-drop-menu {display: block}
.drop-left::after {content: " ";position: absolute;bottom: 100%; right: var(--toolTipRightMarg);border-width: var(--toolTipSize);border-style: solid;border-color:  transparent transparent var(--dropUlBkg) transparent;}
.drop-right::after {content: " ";position: absolute;bottom: 100%; left: var(--toolTipLeftMarg);border-width: var(--toolTipSize);border-style: solid;border-color:  transparent transparent var(--dropUlBkg) transparent;}
 ul.a-btn-drop-menu li {display: block; box-sizing: border-box;overflow: hidden; width: 100%;border-radius: var(--dropBrdRadius);}
 li.a-btn-drop-row {padding: var(--dropLiPadding); text-align: left;background-color: inherit;display: block;text-decoration: none;cursor: pointer; color: var(--dropFontCol);font-size: var(--dropFontSize);line-height: var(--dropLineHe); font-weight: var(--dropFontWeight);}
 li.a-btn-drop-row:hover {  background: var(--dropUlBkgHov);outline: none;}
