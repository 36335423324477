.pr-header-defs {
  --headerHeight: 45vh;
  --header-h1:40px;--head-h1-padRight:7%;--header-h1-2:28px;--header-h1-2-margTop: 20px;
  --headerTextContWidth: 60%;--headerImageContWidth: calc(100% - var(--headerTextWidth)); --headerImgContHeight: 60%;--headerImgWidth:auto;  --imgMargTop:0;
}

.pr-header-container {background: rgba(13, 117, 197, 0.92);height: var(--headerHeight)}
.pr-header-content {max-width: var(--bodyMaxWidth);width: calc(100% - 2 * var(--contentMargin));margin: 0 auto;display: flex;align-items: center;
  justify-content: center; height: 100%; }
.pr-header-text {width: var(--headerTextContWidth);padding-right: var(--head-h1-padRight)}
.pr-header-img {width: var(--headerImageContWidth);height: var(--headerImgContHeight);margin-top: var(--imgMargTop)}
.pr-head-img-size {width: var(--headerImgWidth); height: 100%;margin: 0 auto;display: block;}
.pr-header-h1 {color: white; font-size: var(--header-h1); font-weight: 600;line-height: 1.3}
.pr-header-h1-1st {text-shadow: 2px 2px 2px rgba(0,0,0.6); }
.pr-header-h1-2nd {font-size: var(--header-h1-2); margin-top: var(--header-h1-2-margTop); font-weight: 500;}
.pr-header-h1-3rd {}



  @media only screen and (max-width: 1068px) {
    .pr-header-defs {
      --headerHeight: 45vh;
      --header-h1:3vw;--head-h1-padRight:7%;--header-h1-2:2vw;--header-h1-2-margTop: 20px;
      --headerTextContWidth: 60%;--headerImageContWidth: calc(100% - var(--headerTextWidth)); --headerImgContHeight: 55%;--headerImgWidth:auto;  --imgMargTop:0;
    }
  }

  @media only screen and (max-width: 734px)  {
      .pr-header-defs {
        --headerHeight: calc(100vh - 55px);
        --header-h1:8vw;--head-h1-padRight:0;--header-h1-2:5vw;--header-h1-2-margTop: 4.5vh;
        --headerTextContWidth: 90%;--headerImageContWidth:90%;--headerImgWidth:90%; --headerImgContHeight: auto; --imgMargTop:12vh;
      }
      .pr-header-content {flex-direction: column;}
      .pr-header-h1 {text-align: center;}

  }
