ol.side-menu-ol-sub {padding: 0; margin: 0; list-style-type: none; width: 200%; overflow: hidden; }
ol.side-menu-ol-sub li.side-menu-li-sub {display: inline-block; width: 50%;vertical-align: top; position: relative;}


.side-menu-defs-Amz {
  --categoryTitleColor: rgb(17, 17, 17);
  --categoryTitleSize: 18px;
  --categoryTitleWeight: 700;
  --linkColor:rgb(17, 17, 17);
  --linkSize:14px; /*link-button text size*/
  --linkWeight: normal;/*link-button text font weight */
  --linkHeight: 44px;/*link-button container height. There is no vertical padding but flex centering so the height is important to subsitute the padding */
  --linkBkgHover: rgba(0,0,0,0.05);/*link-button has no background but on hover this background is displayed*/
  --linkContainer:8px; /* Left and right padding of the link-button container visible on hover when the background color is active*/
  --linkIconColor: DodgerBlue; /* the icon inside the button-link if present */
  --linkIconSize:30px;/* the icon inside the button-link if present */
  --linkSubArrowSize: 17px; /* When the link contains a submenu the right arrow appears on the right end of the link-button. The color is the same as the link text, the size is set here */
  --linkSubArrowColor: var(--linkColor);
  --linkIconRightMargin:8px;/* */
  --prevTitleSize:16px;
  --menu-container: 16px;
  --menuTopMargin: 16px;
  --userIntroHeight: 50px;
}

.side-menu-defs{
  --categoryTitleColor: rgb(101, 103, 107);
  --categoryTitleSize: 17px;
  --categoryTitleWeight: 600;
  --linkColor:rgb(5, 5, 5);
  --linkSize:15px; /*link-button text size*/
  --linkWeight: 500;/*link-button text font weight */
  --linkHeight: 44px;/*link-button container height. There is no vertical padding but flex centering so the height is important to subsitute the padding */
  --linkBkgHover: rgba(0,0,0,0.05);/*link-button has no background but on hover this background is displayed*/
  --linkContainer:8px; /* Left and right padding of the link-button container visible on hover when the background color is active*/
  --linkIconColor: DodgerBlue; /* the icon inside the button-link if present */
  --linkIconSize:30px;/* the icon inside the button-link if present */
  --linkSubArrowSize: 18px; /* When the link contains a submenu the right arrow appears on the right end of the link-button. The color is the same as the link text, the size is set here */
  --linkSubArrowColor: var(--linkIconColor);
  --linkIconRightMargin:8px;/* */
  --prevTitleSize:16px;
  --menu-container: 12px;
  --menuTopMargin: 16px;
  --backLinkColor: hsl(210,100%,30%);
  --backLinkColorHover: hsl(210,100%,45%);
  --userIntroHeight: 50px;
}

@media only screen and (max-width: 1068px) {}
@media only screen and (max-width: 734px) {
  .side-menu-defs{
    --categoryTitleSize: 19px;
    --categoryTitleWeight: 600;
    --linkSize:17px; /*link-button text size*/
    --linkWeight: 500;/*link-button text font weight */
    --linkHeight: 44px;/*link-button container height. There is no vertical padding but flex centering so the height is important to subsitute the padding */
    --linkContainer:8px; /* Left and right padding of the link-button container visible on hover when the background color is active*/
    --linkIconSize:30px;/* the icon inside the button-link if present */
    --linkSubArrowSize: 22px; /* When the link contains a submenu the right arrow appears on the right end of the link-button. The color is the same as the link text, the size is set here */
    --linkIconRightMargin:8px;/* */
    --prevTitleSize:18px;
    --menu-container: 8px;
    --menuTopMargin: 12px;
    --userIntroHeight: 60px;
}}
