.patologie-section-cont {max-width: var(--bodyMaxWidth);width: calc(100% - 2 * var(--contentMargin));margin: 0 auto;}
.patologie-header-home {font-size: 30px;margin-bottom: 20px;}
.patologia-cont {width: calc((100% - 16px * 3) / 4); border: 1px solid #c6c6c6; margin-bottom: 16px;display: flex;flex-direction: column;}
.patologie-rows-cont {display: flex;justify-content: space-between;align-items: stretch;flex-wrap: wrap;}
.pat-title {font-weight: 500; font-size: 22px; padding: 12px}
.pat-text {padding: 12px;flex-grow: 1}
.pat-img {width: 100%;padding-bottom: 70%; position: relative;}
.pat-img img {position: absolute;top: 0;width: 100%;height: 100%;object-fit: cover}
.pat-wiki {width: 100%;padding: 12px;}
@media only screen and (max-width: 734px)  {
    .patologia-cont {width: calc((100% - 16px)); border: 1px solid #c6c6c6; margin: 8px;}
}
