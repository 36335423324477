/* General*/
.a-btn {display: inline-block;  cursor: pointer; text-align: center; position: relative;}
.a-btn-drop {position: relative;padding: var(--dropDwonPadding) }
.a-btn-rounded {border-radius: 32px}

/***********Tooltip ************/
.a-btn-tooltip {position: absolute;right:-50%;bottom: -5px; display: none; padding: 8px 4px;transform: translateY(100%);border-radius: 5px;background-color: black; color: white;
  box-shadow: 0 0 4px rgba(0,0,0,.8); font-size: 11px; line-height: 1.2; z-index: 10; width:90px;white-space:normal;text-align: center;  }
.a-btn:hover .a-btn-tooltip {display: block;}

.a-btn-tooltip::after {content: " ";position: absolute;bottom: 100%; right: calc(50% - 7px);border-width: 5px;border-style: solid;border-color:  transparent transparent black transparent;}
/*
li:first-child .a-btn-tooltip {transform: translate(calc((100% - 43px)),100%);}
li:last-child .a-btn-tooltip {transform: translate(0 ,100%);}
li:first-child .a-btn-tooltip::after {content: " ";position: absolute;bottom: 100%; right: calc(100% - 28px);border-width: 5px;border-style: solid;border-color:  transparent transparent black transparent;}
li:last-child .a-btn-tooltip::after {content: " ";position: absolute;bottom: 100%; right: 15px;border-width: 5px;border-style: solid;border-color:  transparent transparent black transparent;}
*/
