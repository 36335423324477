.practice-def {--liSize: 17px;--liLineHe:1.3;--ulLeftPad: 16px; --liColor:rgb(38, 38, 38); --liVPad: 8px;}
.practice-container {background-color: rgb(242,242,242);}
.practice-body {min-height: 70vh; width: 1150px; max-width: calc(100% - 64px);margin: 40px auto;display: flex;flex-wrap: nowrap;background-color: rgb(242,242,242);}
.practice-body-main {width: 60%; margin-right: 2%}
.practice-body-side {width: 38%;}
.pr-cont-box {-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);border-radius: 8px;color: rgb(38, 38, 38); width: 100%;
  background-color: white;overflow: hidden;font-family: "Helvetica Neue", Helvetica, Arial, Utkal, sans-serif!important}
.pr-box-title{font-size: 20px; font-weight: 600; margin-bottom: 12px;color:hsl(35,97%,49%);}
.pr-box-padding {padding: 20px 24px;}
.pr-patologie {display: flex;align-items: flex-start;justify-content: flex-start;;flex-wrap: wrap;}
.pr-patologie li {width: 33.33%}
.pr-box-no-pad-bot {padding-bottom: 0}
.pr-fullMap-cont {height: calc(100vh - 55px); width: 100%}
.fullMap-fixed {position: fixed;z-index: 5;top: 55px;left: 0}
.pr-box-text {font-size: 17px; color: rgb(38,38,38);line-height: 1.3;margin: 20px 0;}
.derm-head {font-size: 20px; margin-bottom: 23px;font-weight: 600; color: rgb(245,245,245)}
.derm-title {max-width: 650px;}
.derm-subtitle {margin-top: 23px; font-size: 20px;max-width: 650px;font-weight: 500}
.pr-box-ul {list-style-type: none;margin: 0; padding: 0 0 0 var(--ulLeftPad);}
.pr-box-ul li {   margin: var(--liVPad) 0;  line-height: var(--liLineHe);  font-size: var(--liSize);  color: var(--liColor);}
.pr-box-ul li::before {  content: "▪";  color: rgb(250, 167, 51);  width: 20px;  margin-left: -16px;  display: inline-block;}
.pr-head-img-cont {width: 65%;padding-bottom: 70%;border-radius: 50%; background-size: cover;background-position: center;background-repeat: no-repeat;}
.pr-fullMap-action {position: absolute; top: 8vh;right: 5vw;}
@media only screen and (max-width: 734px)  {
  .pr-head-img-cont{display: none}
  .practice-body {flex-wrap: wrap;width: 100%; max-width: 100%}
  .practice-body-main {width: calc(100vw - 32px); margin: 0 16px;}
  .practice-body-side {width: calc(100vw - 32px);margin: 0 16px;}
}
