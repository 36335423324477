/* Colors , border */
/*.a-btn-bordered {border: var(--brdSize) solid hsl(var(--h),var(--s),93%); color:  hsl(var(--h),var(--s),93%); background-color: inherit; }
.a-btn-bordered:hover {border-color: hsl(var(--h),var(--s),83%); color: hsl(var(--h),var(--s),83%);}
.a-btn-bordered .material-icons {color: hsl(var(--h),var(--s),93%)!important; }
.a-btn-bordered:hover .material-icons{color: hsl(var(--h),var(--s),83%)!important; }
*/
.a-btn-bordered {border: var(--brdSize) solid hsl(var(--h),var(--s),var(--l)); color:  hsl(var(--h),var(--s),var(--l)); background-color: inherit; }
.a-btn-bordered:hover {border-color: hsl(var(--h),var(--s),calc(var(--l) + 10%)); color: hsl(var(--h),var(--s),calc(var(--l) + 10%));}
.a-btn-bordered .material-icons {color: hsl(var(--h),var(--s),var(--l))!important; }
.a-btn-bordered:hover .material-icons{color: hsl(var(--h),var(--s),calc(var(--l) + 10%))!important; }

.a-btn-bordOnHover {border: var(--brdSize) solid transparent;color: var(--btn-color); background-color: inherit; }

.a-btn-bordOnHover .material-icons{color: var(--btn-color)!important; }
.a-btn-bordOnHover .a-btn-tooltip {bottom: -7px;}

.a-btn-textOnHover {border: none;background-color: hsl(var(--h),var(--s),var(--l)); color: var(--btn-color); }
.a-btn-textOnHover .material-icons{color: var(--btn-color)!important; }

.a-btn-textOnHover .a-btn-tooltip {bottom: 0;}

.a-btn-filled {border: none;background-color: hsl(var(--h),var(--s),var(--l)); color: var(--btn-color); }
.a-btn-filled.a-btn-opac {border: none;background-color: hsla(var(--h),var(--s),var(--l),.4); color: var(--btn-color); }
.a-btn-filled .material-icons {color: var(--btn-color)!important; }

.a-btn-HW {border: 1px solid transparent;background-color: rgba(0,0,0,.3); color: white; }

.a-btn-HW .material-icons{color: white!important; }
.a-btn-HW .a-btn-tooltip {bottom: -7px;}

.a-btn-filled-dark {border: none;background-color: hsl(var(--h),var(--s),var(--l)); color: var(--btn-color); }
.a-btn-filled-dark.a-btn-opac {border: none;background-color: hsl(var(--h),var(--s),var(--l),.3); color: var(--btn-color); }
.a-btn-filled-dark .material-icons{color: var(--btn-color)!important; }


@media only screen and (min-width: 1300px) {
    .a-btn-bordOnHover:hover {border: var(--brdSize) solid var(--btn-color); }
    .a-btn-textOnHover:hover, .a-btn-textOnHover:hover .material-icons {color: var(--btn-colorH, hsl(var(--h),var(--s),83%)) !important;  }
    .a-btn-filled:hover:not(.no-hover) {background-color:hsl(var(--h),var(--s),calc(var(--l) + 10%)); }
    .a-btn-HW:hover {border: 1px solid #a9afbc;}
    .a-btn-filled-dark:hover {background-color:hsl(var(--h),var(--s),calc(var(--l) - 7%))}
}
