/*


@media only screen and (max-width: 1068px) {

}

@media only screen and (max-width: 734px)  {

}



*/

.body-pad {padding-top: 55px}

/*************** Scroll Bar Style **************/
h1,h2,h3,h4,h5,h6 {margin: 0; padding: 0}

 /* width #0057B8, #FFD700*/
::-webkit-scrollbar {
  width: 7px;
}

:root,body {height: 100%;}

/* Track */

:root {scrollbar-color: #0057B8 #FFD700;}

::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 8px grey;*/
  border-radius: 10px;
  background-color: #0057B8;
}



/* Handle */
::-webkit-scrollbar-thumb {
  background: #FFD700;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /*background: hsl(35, 95%,65%);*/
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
:root {--content: 1380px;}
.fb-shadow {box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;}
 /*full height centered image - if width < height there will be margins left/right, if width >= height the img will be cut on both side to make the width == height*/
.fake-block {height: 100vh; background: red}
.animated {animation-fill-mode: forwards; animation-duration: 1s; opacity: 0}
.a-square-img {position: relative; overflow: hidden; width: 100%; height: 100%} /* height js set to exactly 100%, width is set min 100% with the overflowing width not displayed*/
.a-square-img > img {height: 100%; max-height: 100%; max-width: none; min-width: 100%; margin: auto; position: absolute; left: -9999px; top: -9999px; right: -9999px; bottom: -9999px;}
.an-inline {display: inline ;}
.an-inline-block {display: inline-block;}
.gray-hover{background-color: white} .gray-hover:hover{background-color: #f2f2f2}
.l-blue-hover{background-color: white} .l-blue-hover:hover{background-color: rgba(78, 137, 204, 0.39)}
.a-nowrap{  white-space: nowrap;}
.a-text-right {text-align: right!important;}
.a-size-base {  font-size: 14px!important;line-height: 20px!important;}
.no-hover {cursor: auto !important}
.invisible, .invisible div, .invisible span, .invisible a {visibility: hidden; }
.auto-cursor {cursor: auto !important;}
body {box-sizing: border-box; font-family: "Segoe UI", Arial, sans-serif;}
.d16 {width: 16px; height: 16px; line-height: 16px} .d17 {width: 17px; height: 17px; line-height: 17px}.d20 {width: 20px; height: 20px; line-height: 20px}.d24 {width: 24px; height: 24px; line-height: 24px}
.d25 {width: 25px; height: 25px; line-height: 25px} .d30 {width: 30px; height: 30px; line-height: 30px}
.ZI10 {z-index: 10}
hr {background-color: transparent;display: block;height: 1px;border-width: 0;border-top: 1px solid #e7e7e7;line-height: 19px;margin-top: 0;margin-bottom: 0;}
/**** flex **********/
.flx-btw {display: flex; align-items: center; justify-content: space-between; flex-wrap: nowrap;}
.flx-cen-btw {display: flex; align-content: space-between; justify-content: center; flex-direction: column;}
.flx-btw-str {display: flex; align-items: stretch; justify-content: space-between; flex-wrap: nowrap;}
.flx-btw-btm {display: flex; align-items: flex-end; justify-content: space-between; }
.flx-self-top {align-self: flex-start;}
.no-shrink {flex-shrink: 0;}
.grow-1 {flex-grow: 1}
.a-flex-col, .flex-col{display: flex; flex-direction: column !important;}
.flx-cen {display: flex; justify-content: center;}
.flx-cen-btm {display: flex;justify-content: center; align-items: flex-end;}
.flx-al-btm {align-items: flex-end;}.flx-al-top {align-items: flex-start;}.flx-al-cen {align-items: center;}
.flx-aTop-Jcen {display: flex; justify-content: center; align-items: flex-start; flex-wrap: nowrap;}
.flx-top-start {display: flex; justify-content: flex-start; align-items: flex-start; }
.a-wrap {flex-wrap: wrap !important;}
.a-no-wrap {flex-wrap: nowrap !important}
.flx-stretch {align-items: stretch !important;}
/*********************flex ************/



.a-sticky-col {position: -webkit-sticky; position: sticky; top: 0px;  height: 100%;}
.an-oriz-str-img {width: 100%; height: auto}
ul.ul-clear {margin: 0; padding: 0; list-style-type: none;}
ul.ul-clear > li {list-style: none; display: block;}
ul.ul-clear-inline {margin: 0; padding: 0; list-style-type: none;}
ul.ul-clear-inline > li {list-style: none; display: inline-block;}
/*************** Scroll Bar Style **************/
.overX-Hid {overflow-x: hidden; overflow-y: visible;}
.ov-hidd, .over-hid, .o-hid, .ov-hid {overflow: hidden}
.max-content {margin-left: auto; margin-right: auto; max-width: 1400px;}
.zI1 {z-index: 1}
.z-ind-4 {z-index: 4;}
.back-image-cover {background-size: cover; background-repeat: no-repeat; background-position: center;  position: relative;}
.fullscreen-gym-slider {margin-bottom: 16px; margin-right: 16px; padding: 8px}
.home-block-container {margin: 64px auto}
.home-block-title-container {padding: 30px 40px 50px 40px; line-height: 1; font-weight: bold; font-size: 32px}
.mainTheme {color:#fff ; background-color:#3b5da1 !important}
.truncate{white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.app-content {max-width: 1280px; margin: 0 auto; padding: 20px 0;}
.tx-right-align {text-align: right;}
.tx-left-align {text-align: left;}
@media (max-width:900px) {
.fullscreen-gym-slider {margin-bottom: 8px; margin-right: 8px; padding: 0px}
.home-block-container {margin: 32px auto}

}

@media (max-width:520px) {
.mainTheme {background-color:white !important}
.home-block-container {border-top: 3px solid #35538f; margin: 0 auto !important;; padding-bottom: 10px}
.home-block-title-container {padding: 12px 16px 22px 16px; font-size: 6vw;}
}

@media (min-width:521px){

.zzhome-block-container {box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px 0px, rgba(0, 0, 0, 0.188235) 0px 4px 20px 0px; border-radius: 10px; overflow: hidden;}
}
@media (min-width:901px) {

}
@media (min-width:1200px) {

}

@media (min-width: 520px) and (max-height: 450px){
  .home-block-container {margin-left: 8px !important; margin-right: 8px !important}
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


/*
Shaking effect
.ts-inpage-push-dating__cta-button {
    position: absolute;
    text-align: center;
    top: 50%;
    right: 15px;
    width: 60px;
    height: 28px;
    line-height: 28px;
    margin-top: -14px;
    animation: linear shaking 2s infinite 3s;
    background: #c837ab;
    background: -moz-linear-gradient(-45deg, #c837ab 0, #ff543e 75%, #ff9349 100%);
    background: -webkit-linear-gradient(-45deg, #c837ab 0, #ff543e 75%, #ff9349 100%);
    background: linear-gradient(135deg, #c837ab 0, #ff543e 75%, #ff9349 100%);
    border-radius:14px
}



*/

@keyframes shaking {
    0%, 7% {
        transform:rotateZ(0)
    }

    15% {
        transform:rotateZ(-15deg)
    }

    20% {
        transform:rotateZ(10deg)
    }

    25% {
        transform:rotateZ(-10deg)
    }

    30% {
        transform:rotateZ(6deg)
    }

    35% {
        transform:rotateZ(-4deg)
    }

    100%, 40% {
        transform:rotateZ(0)
    }
}

.hero-image-wrapper .overlay[data-v-95e8bf52] {
    background-color: rgba(17,17,17,.35);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.hero-image-wrapper .overlay[data-v-95e8bf52]::before {
    content: "";
    position: absolute;
    height: 5rem;
    top: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(0deg,hsla(0,0%,100%,0),rgba(0,0,0,.7));
}

.hero-image-wrapper .overlay[data-v-95e8bf52]::after {
    content: "";
    position: absolute;
    height: 11.25rem;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg,transparent 0,#101010);
}
