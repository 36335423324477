.home-defs {
  --bodyMaxWidth:1200px; --contentMargin:0;

}
.dent-body-cont {max-width: var(--bodyMaxWidth);width: calc(100% - 2 * var(--contentMargin));margin: 0 auto;display: flex;align-items: stretch;
  justify-content: space-between;flex-wrap: wrap;}



@media only screen and (max-width: 1068px) {
    .home-defs {--bodyMaxWidth:1200px; --contentMargin:32px}
}

@media only screen and (max-width: 734px)  {
    .home-defs {--bodyMaxWidth:100%; --contentMargin:8px}
}
