/* Size: border, font, padding, weight*/
.a-btn-size {--brdSize: 1px;border-radius: 6px; font-size: 14px; line-height: 20px; padding: 0 18px;height: 38px; letter-spacing: 0.5px;font-weight: 500; }
.a-btn-size-40 {--brdSize: 1px;border-radius: 6px; font-size: 16px; line-height: 20px; padding: 0 18px;height: 40px; letter-spacing: 0.5px;font-weight: 500; }
.a-btn-size-40-icon {--brdSize: 1px;border-radius: 6px; font-size: 14px;--iconSize:26px; line-height: 20px; padding: 0 18px;height: 40px; letter-spacing: 0.5px;font-weight: 500;
--iconLePad:0px;--iconRiPad:0px;--iconToPad:0px;--iconBoPad:0px;
 }
.a-btn-size-2 {--brdSize: 1px;border-radius: 19px; font-size: 14px; line-height: 15px; padding: 0 18px;height: 38px; letter-spacing: 0.5px;font-weight: 500; }
.a-btn-size-mini {--brdSize: 1px;border-radius: 11px; font-size: 12px; line-height: 12px; padding: 0 22px;height: 22px; letter-spacing: 0.5px;font-weight: 500; }
.a-btn-size-3 {--brdSize: 1px;border-radius: 21px; font-size: 16px; line-height: 16px; padding: 0 18px;height: 42px; letter-spacing: 0.5px;font-weight: 500; }
.a-btn-size-4 {--brdSize: 1px;border-radius: 24px; font-size: 18px; line-height: 20px; padding: 0 24px;height: 45px; letter-spacing: 0.5px;font-weight: 600; }
.a-btn-size-6 {--brdSize: 1px;border-radius: 32px; font-size: 23px; line-height: 20px; padding: 0 40px;height: 64px; letter-spacing: 0.5px;font-weight: 600; }
.a-btn-size-15 {--brdSize: 1px;border-radius: 18px; font-size: 15px; line-height: 1; padding: 0 20px;height: 36px; letter-spacing: 0.5px;font-weight: 500; }
.a-btn-size-3-icon {--brdSize: 1px;border-radius: 50%; font-size: 30px; line-height: 30px; padding: 0 10px;height: 50px; letter-spacing: 0.5px;font-weight: 500; }
.a-btn-size-cs {--brdSize: 1px;border-radius: 9px; font-size: 22px; line-height: 22px; padding: 0 20px;height: 56px; letter-spacing: 0.5px;font-weight: 600;touch-action: manipulation; }


.a-btn-top {--brdSize: 0;border-radius: 6px; font-size: 15px; line-height: 1; padding: 0 18px;--dropDwonPadding: 0 8px 0 14px; height: 36px; letter-spacing: 0.5px;font-weight: 500;}
.a-btn-top-icon {--brdSize: 0;border-radius: 50%; --iconSize: 24px; line-height: 1; padding: 0 9px;height: 42px; font-weight: 500; margin-right: 4px;
  --iconLePad:2px;--iconRiPad:0px;--iconToPad:0px;--iconBoPad:0px;}
.a-btn-top-icon-text {--brdSize: 0;border-radius: 20px;font-size: 15px; --iconSize: 24px; line-height: 1; padding: 0 18px 0 12px;height: 40px; font-weight: 500;--dropDwonPadding: 0 8px 0 9px;
  --iconLePad:0px;--iconRiPad:0px;--iconToPad:0px;--iconBoPad:0px;}



.a-btn-top.a-btn-margin, .a-btn-top-icon.a-btn-margin {margin-right: 8px}

li:last-child .a-btn-top.a-btn-margin,li:last-child .a-btn-top-icon.a-btn-margin {margin-right: 0}

.a-btn-top-noBkg {--brdSize: 1px;border-radius: 6px; font-size: 18px; line-height: 1; padding: 0 14px;--dropDwonPadding: 0 8px 0 14px; height: 36px; letter-spacing: 0.5px;font-weight: 500; --noBkgMarginRight: 20px; }
.a-btn-top-icon-noBkg {--brdSize: 1px;border-radius: 50%; --iconSize: 24px; line-height: 24px; padding: 0 9px;height: 38px; font-weight: 500; margin-right: 6px;text-shadow: 4px 4px 16px rgba(0,0,0,1);--iconLePad:2px;--iconRiPad:0px;--iconToPad:0px;--iconBoPad:0px;--noBkgMarginRight: 20px; }
.a-btn-top-noBkg-icon-text {--brdSize: 1px;border-radius: 20px; font-size: 18px;--iconSize: 24px; line-height: 1; padding: 0 14px 0 8px;--dropDwonPadding: 0 8px 0 14px; height: 40px; letter-spacing: 0.5px;font-weight: 500;--iconLePad:0px;--iconRiPad:0px;--iconToPad:0px;--iconBoPad:0px;--noBkgMarginRight: 20px;  }

.a-btn-top-noBkg.a-btn-margin, .a-btn-top-icon-noBkg.a-btn-margin {margin-right: var(--noBkgMarginRight)}
li:last-child .a-btn-top-noBkg.a-btn-margin, li:last-child .a-btn-top-icon-noBkg.a-btn-margin {margin-right: 0}



@media only screen and (max-width: 1068px) {
  .a-btn-size-40-icon {--brdSize: 1px;border-radius: 6px; font-size: 14px;--iconSize:26px; line-height: 26px; padding: 0;height: 40px; letter-spacing: 0.5px;font-weight: 500;
  --iconLePad:0px;--iconRiPad:0px;--iconToPad:0px;--iconBoPad:0px;}
  .a-btn-top-noBkg {--brdSize: 1px;border-radius: 6px; font-size: 15px;  padding: 0 10px;--dropDwonPadding: 0 6px 0 12px; height: 32px; --noBkgMarginRight: 12px;}
  .a-btn-top-icon-noBkg {--brdSize: 1px;border-radius: 50%; --iconSize: 20px; line-height: 20px; padding: 0 7px;height: 32px;  margin-right: 3px;text-shadow: 4px 4px 16px rgba(0,0,0,1);--noBkgMarginRight: 12px;}
  .a-btn-top-noBkg-icon-text {--brdSize: 1px;border-radius: 16px; font-size: 15px;--iconSize: 20px; line-height: 1; padding: 0 14px 0 8px;--dropDwonPadding: 0 6px 0 12px; height: 32px;--noBkgMarginRight: 12px;  }
  .a-btn-top {--brdSize: 0;border-radius: 6px; font-size: 14px; line-height: 1; padding: 0 12px;--dropDwonPadding: 0 5px 0 12px; height: 34px; }
  .a-btn-top-icon {--brdSize: 0;border-radius: 50%; font-size: 22px; line-height: 20px; padding: 0 7px;height: 32px;  margin-right: 3px;}
  .a-btn-top-icon-text {--brdSize: 0;border-radius: 16px;font-size: 14px; --iconSize: 22px; line-height: 1; padding: 0 12px 0 6px;height: 32px; font-weight: 500;--dropDwonPadding: 0 6px 0 7px;
    --iconLePad:0px;--iconRiPad:0px;--iconToPad:0px;--iconBoPad:0px;}
  .a-btn-size-15 {--brdSize: 1px;border-radius: 16px; font-size: 14px;  padding: 0 16px;height: 32px;  }
  .a-btn-size {--brdSize: 1px;border-radius: 6px; font-size: 14px; line-height: 20px; padding: 0 18px;height: 38px; letter-spacing: 0.5px;font-weight: 500; }
  .a-btn-size-cs {--brdSize: 1px;border-radius: 9px; font-size: 21px; line-height: 22px; padding: 0 19px;height: 52px; letter-spacing: 0.5px;font-weight: 600;touch-action: manipulation; }
}

@media only screen and (max-width: 734px) {
  .a-btn-size-40-icon {--brdSize: 1px;border-radius: 6px; font-size: 14px;--iconSize:22px; line-height: 20px; padding: 0;height: 35px; letter-spacing: 0.5px;font-weight: 500;}
  .a-btn-top-noBkg {--brdSize: 1px;border-radius: 6px; font-size: 15px;  padding: 0 10px;--dropDwonPadding: 0 6px 0 12px; height: 32px; --noBkgMarginRight: 12px;}
  .a-btn-top-icon-noBkg {--brdSize: 1px;border-radius: 50%; --iconSize: 24px; line-height: 20px; padding: 0 7px;height: 32px;  margin-right: 3px;text-shadow: 4px 4px 16px rgba(0,0,0,1);--noBkgMarginRight: 12px;}
  .a-btn-top-noBkg-icon-text {--brdSize: 1px;border-radius: 16px; font-size: 15px;--iconSize: 20px; line-height: 1; padding: 0 14px 0 8px;--dropDwonPadding: 0 6px 0 12px; height: 32px;--noBkgMarginRight: 12px;  }
  .a-btn-top {--brdSize: 0;border-radius: 6px; font-size: 13px; line-height: 1; padding: 0 8px;--dropDwonPadding: 0 3px 0 8px; height: 34px; }
  .a-btn-top-icon {--brdSize: 0;border-radius: 50%; --iconSize: 24px; line-height: 20px; padding: 0 min(1.4vw, 6px);height: 32px;  margin-right: 3px;}
  .a-btn-top-icon-text {--brdSize: 0;border-radius: 16px;font-size: 14px; --iconSize: 20px; line-height: 1; padding: 0 12px 0 6px;height: 32px; font-weight: 500;--dropDwonPadding: 0 6px 0 7px;
    --iconLePad:0px;--iconRiPad:0px;--iconToPad:0px;--iconBoPad:0px;}
  .a-btn-size-2 {--brdSize: 1px;border-radius: 16px; font-size: 13px; line-height: 15px; padding: 0 12px;height: 32px; letter-spacing: 0.5px;font-weight: 500; }
  .a-btn-size-6 {--brdSize: 1px;border-radius: 29px; font-size: 19px; line-height: 19px; padding: 0 35px;height: 58px; letter-spacing: 0.5px;font-weight: 600; }
  .a-btn-size-cs {--brdSize: 1px;border-radius: 9px; font-size: 19px; line-height: 19px; padding: 0 17px;height: 47px; letter-spacing: 0.5px;font-weight: 600;touch-action: manipulation; }
}

@media only screen and (max-width: 385px) {
    .a-btn-size-40-icon {--brdSize: 1px;border-radius: 6px; font-size: 14px;--iconSize:20px; line-height: 20px; padding: 0;height: min(8.2vw, 32px); letter-spacing: 0.5px;font-weight: 500;}
}

@media only screen and (max-width: 335px) {
    .a-btn-size-40-icon {--brdSize: 1px;border-radius: 6px; font-size: 14px;--iconSize:18px; line-height: 1; padding: 0;height: min(7.5vw, 26px); letter-spacing: 0.5px;font-weight: 500;}
}

.a-btn-icon-container {width: calc(var(--iconSize) + var(--iconLePad) + var(--iconRiPad)); height: calc(var(--iconSize) + var(--iconToPad) + var(--iconBoPad)); padding: var(--iconToPad) var(--iconRiPad) var(--iconBoPad) var(--iconLePad)}
.a-btn-icon-container  .material-icons {font-size: var(--iconSize);}
